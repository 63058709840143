import i18n from 'i18next'
import intervalPlural from 'i18next-intervalplural-postprocessor'
import { initReactI18next } from 'react-i18next'

const resources = {};

function importAll(requiredItems, domain) {
  requiredItems.keys().forEach(key => {
    const filename  = key.replace(/^.*[\\\/]/, '').replace('.json', '')
    resources[`${filename}${domain}`] = requiredItems(key);
  })
}

importAll(require.context('./locales/it_IT/components/', true, /\.json$/), 'Component');
importAll(require.context('./locales/it_IT/containers/', true, /\.json$/), 'Container');
importAll(require.context('./locales/it_IT/pages/', true, /\.json$/), 'Page');
resources['common'] = require('./locales/it_IT/common.json');

i18n
  .use(initReactI18next)
  .use(intervalPlural)
  .init({
    lng: 'it',
    fallbackLng: 'it',
    resources: {
      it: resources,
    },
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
