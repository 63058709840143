import { actionTypes, CommonAction } from './actions'

interface CommonState {
  isLoading: boolean
}

export const initialState: CommonState = {
  isLoading: true,
}

export const reducers = (state: CommonState = initialState, action: CommonAction): CommonState => {
  const { type } = action

  switch (type) {
    case actionTypes.TOGGLE_LOADER:
      return {
        ...state,
        isLoading: typeof action.payload === 'undefined' ? !state.isLoading : action.payload,
      }
    default:
      return state
  }
}
