export const colors = {
  baseBackgroundLight: '#fcfbfe',
  baseWhite: '#ffffff',
  baseDark: '#262626',
  feedbackError: '#cc0b15',
  feedbackSuccess: '#148545',
  feedbackWarning: '#ffb81f',
  baseNeutral: {
    100: '#f5f5f7',
    200: '#efeff1',
    300: '#dadde1',
    400: '#c1c7cf',
    500: '#a3a9b1',
    700: '#656b72',
    800: '#464b52',
    80050: 'rgba(70, 75, 82, 0.5)',
  },
  primaryGreen: {
    50: '#dff5eb',
    300: '#2ec58d',
    500: '#dff5eb',
    600: '#41a476',
    800: '#1f855f',
    900: '#1b4832',
  },
  primaryGreenCheckboxFilter: {
    500: '#1b4832',
    600: '#1b4832',
  },
  primaryGreenCheckboxNltStep: {
    500: '#41a476',
    600: '#41a476',
  },
  secondaryBlu: {
    700: '#42759d',
    800: '#39658b',
    900: '#2b4d6b',
  },
  borders: {
    grey: '#d9dde1',
  },
}
