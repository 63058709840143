export const spaces = {
  xs: '0.5rem',
  s: '0.625rem',
  m: '1rem',
  l: '1.5rem',
  xl: '2rem',
  xxl: '2.5rem',
  safeAreaMobile: '1.375rem',
  xxl2: '3rem',
  xxl3: '3.5rem',
  xxl4: '4rem',
  xxl5: '5rem',
  xxl6: '5.5rem',
  xxs: '0.25rem',
}
