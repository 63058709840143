import { combineReducers } from 'redux'
import { HYDRATE } from 'next-redux-wrapper'
import queryStringManager from 'query-string-manager'
import * as common from './common'
import * as user from './user'
import * as nltOffer from './nlt-offer'
import * as sellOffer from './sell-offer'

const rootReducer = combineReducers({
  common: common.reducers,
  user: user.reducers,
  nlt: nltOffer.reducers,
  sell: sellOffer.reducers,
})

const reducer = (state, action): Store.RootState => {
  switch (action.type) {
    case HYDRATE:
      if (action.payload.user.profile === null) {
        action.payload.user.profile = state.user.profile
      }

      if (typeof window !== 'undefined') {
        const filters = fromQueryStringToFilters()
        const page = fromQueryStringToPage()

        action.payload.sell.selectedFilters = filters
        action.payload.nlt.selectedFilters = filters

        action.payload.sell.listingPage = page
        action.payload.nlt.listingPage = page
      }

      return { ...state, ...action.payload }
    default:
      return rootReducer(state, action)
  }
}

const fromQueryStringToFilters = () => {
  const query = typeof window === 'undefined' ? {} : queryStringManager.read().get()

  if (Object.entries(query).length) {
    let result = []

    for (let key in query) {
      let value = query[key]
      value = Array.isArray(value) ? value[0] : `${value}`
      if (key !== 'pagina' && value !== '') {
        // @ts-ignore
        if (value.indexOf(',') >= 0) {
          value
            // @ts-ignore
            .split(',')
            .filter((v, idx, arr) => arr.indexOf(v) === idx)
            .forEach(val => {
              const exists = result.findIndex(el => el.key === key)
              if (exists >= 0) {
                result[exists].items.push({ value: val, name: val })
              } else {
                result.push({
                  key,
                  items: [{ value: val, name: val }],
                })
              }
            })
        } else {
          result.push({
            key,
            items: [{ value, name: value }],
          })
        }
      }
    }

    return result
  }

  return []
}

const fromQueryStringToPage = () =>
  typeof window === 'undefined' ? 1 : queryStringManager.read().get('pagina', 1)

export default reducer
