import { all, takeLatest, AllEffect, ForkEffect } from 'redux-saga/effects'

import { actionTypes as nltActionTypes } from 'src/redux/nlt-offer/actions'
import { actionTypes as sellActionTypes } from 'src/redux/sell-offer/actions'
import { searchOffers as searchNltOffers } from 'src/redux/nlt-offer/saga'
import { searchOffers as searchSellOffers } from 'src/redux/sell-offer/saga'

function* rootSaga(): Generator<AllEffect<ForkEffect<never>>, void, unknown> {
  yield all([
    takeLatest(
      [
        nltActionTypes.TOGGLE_FILTER,
        nltActionTypes.RESET_FILTERS,
        nltActionTypes.SET_LISTING_PAGE,
        nltActionTypes.TOGGLE_ORDER,
        nltActionTypes.PERFORM_SEARCH_OFFERS,
      ],
      searchNltOffers,
    ),
    takeLatest(
      [
        sellActionTypes.TOGGLE_FILTER,
        sellActionTypes.RESET_FILTERS,
        sellActionTypes.SET_LISTING_PAGE,
        sellActionTypes.TOGGLE_ORDER,
        sellActionTypes.PERFORM_SEARCH_OFFERS,
      ],
      searchSellOffers,
    ),
  ])
}

export default rootSaga
