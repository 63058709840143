import { extendTheme } from '@chakra-ui/react'
import { colors } from './colors'
import { spaces } from './spaces'
import { texts } from './texts'

export const theme = extendTheme({
  config: {
    cssVarPrefix: 'cp',
  },
  fonts: {
    body: 'Montserrat-Regular, sans-serif',
  },
  fontType: {
    regular: 'Montserrat-Regular, sans-serif',
    medium: 'Montserrat-Medium, sans-serif',
    semiBold: 'Montserrat-SemiBold, sans-serif',
  },

  colors: {
    ...colors,
  },
  space: {
    ...spaces,
  },

  textStyles: {
    ...texts,
  },

  shadows: {
    largeSoft: 'rgba(60, 64, 67, 0.15) 0px 2px 10px 6px;',
    cards: '0 2px 8px 0px rgba(65, 164, 118, 0.12)',
    outline: 'none',
  },
  styles: {
    global: {
      'html, #__next': {
        height: '100%',
        backgroundColor: '#f0f2f5',
      },
      '#__next': {
        display: 'flex',
        flexDirection: 'column',
      },
      '.body': {
        // height: '100%', // Push footer to bottom
        overflowY: 'scroll', // Always show scrollbar to avoid flickering
      },
      html: {
        scrollBehavior: 'smooth',
      },
      '#nprogress': {
        pointerEvents: 'none',
      },
      '#nprogress .bar': {
        background: 'green.200',
        position: 'fixed',
        zIndex: '1031',
        top: 0,
        left: 0,
        width: '100%',
        height: '2px',
      },
    },
  },
  components: {
    //fix safari modal
    Modal: {
      baseStyle: {
        dialogContainer: {
          '@supports(height: -webkit-fill-available)': {},
        },
      },
    },
  },
})
