import React from 'react'
import Error from 'src/components/Error'

const ErrorPage = ({ statusCode }) => <Error statusCode={statusCode} />

export const getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404
  console.log('statusCode', statusCode)
  return { statusCode }
}

export default ErrorPage
