import React, { FC } from 'react'
import NextHead from 'next/head'
import styled from 'styled-components'
import { Button, Link } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0;
`

const ErrorContainer = styled.div`
  width: 400px;
  border-radius: 8px;
  border: 1px solid #ececec;
  text-align: center;
  padding: 24px;
`

const BackButton = styled(Button)`
  width: 100%;
  height: 40px;
  margin-top: 20px;
  font-size: 18px;
`

const Message = styled.span`
  font-size: 16px;
`

interface Props {
  statusCode: number
  message?: string
}

const Error: FC<Props> = ({ statusCode, message }) => {
  const { t } = useTranslation()
  let statusCodeNumber = statusCode || 404;

  return (
    <ErrorWrapper>
      <NextHead>
        <title>
          {message || `${statusCodeNumber} - ${t(`common:error.${statusCodeNumber}`)}`}
          {' '}| CarPlanner
        </title>
      </NextHead>
      <ErrorContainer>
        <h2>:(</h2>
        <h3>{t(`common:error.${statusCodeNumber}`)}</h3>
        {message && <Message>{message}</Message>}
        <BackButton type="primary">
          <Link href="/">{t('common:back_home')}</Link>
        </BackButton>
      </ErrorContainer>
    </ErrorWrapper>
  )
}

export default Error
