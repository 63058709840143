import queryStringManager from 'query-string-manager'
import { actionTypes, NltAction } from './actions'

export type NltState = {
  filterMenuIsOpen: boolean
  filters: Array<Filter.ListItem>
  suggestedFilters: Array<Filter.SuggestedItem>
  selectedFilters: Array<Filter.SuggestedItem>
  hiddenSelectedFilters: Array<SearchEngine.FilterItem>
  offers: Array<OfferGlobal.RentOfferItem | OfferGlobal.AutoPromoItem>
  filteredOffers: OfferGlobal.OffersList
  listingPage: number
  customizedOffer: OfferGlobal.RentCustomizedOffer
  contractualCategories: Array<SearchEngine.ContractualCategory>
  orders: Array<Filter.Order>
  selectedOrder: Filter.Order | null
  customizeModalIsOpen: boolean
  customizeModalStep: number
  errors: []
  priceVatIncluded: boolean
}

export const initialState: NltState = {
  filterMenuIsOpen: true,
  filters: [],
  suggestedFilters: [],
  selectedFilters: [],
  hiddenSelectedFilters: [],
  offers: [],
  filteredOffers: { pageSize: 0, currentPage: 0, nextPage: 0, lastPage: 0, results: 0, items: [] },
  listingPage: 1,
  contractualCategories: [],
  customizedOffer: null,
  orders: [],
  selectedOrder: null,
  customizeModalIsOpen: false,
  customizeModalStep: 0,
  errors: [],
  priceVatIncluded: false,
}

export const reducers = (state: NltState = initialState, action: NltAction): NltState => {
  const { type } = action

  switch (type) {
    case actionTypes.TOGGLE_FILTER_MENU:
      return {
        ...state,
        filterMenuIsOpen: !state.filterMenuIsOpen,
      }
    case actionTypes.GET_FILTERS_SUCCESS:
      var { orders, filters, suggested } = action.payload

      var filters = filters.filter(filterGroup => filterGroup.key !== 'scadenza')

      filters.unshift({
        group: 'OFFERTE IN SCADENZA',
        label: 'Visualizza solo queste offerte',
        type: 'switch',
        multiple: '',
        key: 'scadenza',
        items: [
          {
            value: '1',
            name: 'In scadenza',
            label: 'In scadenza',
            icon: null,
          },
        ],
      })

      return {
        ...state,
        orders,
        filters,
        suggestedFilters: suggested,
      }
    case actionTypes.GET_OFFER_DETAIL_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    case actionTypes.RESET_OFFERS_DATA:
      return {
        ...state,
        offers: initialState.offers,
      }
    case actionTypes.GET_OFFERS_SUCCESS:
      return {
        ...state,
        offers: action.payload.offers,
      }
    case actionTypes.GET_FILTERED_OFFERS_SUCCESS:
      var { pageSize, currentPage, nextPage, lastPage, results, items } = action.payload.offers

      return {
        ...state,
        filteredOffers: {
          pageSize,
          currentPage,
          nextPage,
          lastPage,
          results,
          items,
        },
      }
    case actionTypes.SET_CUSTOMIZED_OFFER:
      return {
        ...state,
        customizedOffer: action.payload,
      }
    case actionTypes.SET_LISTING_PAGE:
      if (typeof window !== 'undefined') {
        queryStringManager.push('pagina', action.payload)
      }

      return {
        ...state,
        listingPage: action.payload,
      }
    case actionTypes.TOGGLE_FILTER:
      const { filterValue, groupKey } = action.payload
      var { selectedFilters, filters } = state

      const isMulti =
        (filters.find(filterItem => filterItem.key === groupKey) || { multiple: '0' }).multiple === '1'
      const filterGroup = selectedFilters.find(selectedFilter => selectedFilter.key === groupKey) || {
        key: groupKey,
        items: [],
      }

      let selectedFiltersNew = selectedFilters.filter(selectedFilter => selectedFilter.key !== groupKey)

      const isSelected = selectedFilters.some(
        filterGroup =>
          filterGroup.key === groupKey &&
          filterGroup.items.some(groupItem => groupItem.value === filterValue),
      )

      const currentFilterParam = queryStringManager.get(groupKey)

      if (isSelected) {
        filterGroup.items = filterGroup.items.filter(item => item.value !== filterValue)

        if (isMulti && currentFilterParam && currentFilterParam.indexOf(',') >= 0) {
          const newFilterParam = currentFilterParam
            .split(',')
            .filter((currentParamValue, idx, arr) => arr.indexOf(currentParamValue) === idx)
            .filter(currentParamValue => currentParamValue !== filterValue)
            .join(',')
          queryStringManager.push(groupKey, newFilterParam)
        } else {
          queryStringManager.remove(groupKey)
        }
      } else {
        const selectedFilterItem = filters
          .find(filterItem => filterItem.key === groupKey)
          .items.find(item => item.value === filterValue)

        if (isMulti) {
          filterGroup.items.push(selectedFilterItem)
        } else {
          filterGroup.items = [selectedFilterItem]
        }

        const newFilterParam =
          isMulti &&
          currentFilterParam &&
          ((currentFilterParam.indexOf(',') < 0 && currentFilterParam !== filterValue) ||
            (currentFilterParam.indexOf(',') >= 0 &&
              currentFilterParam.split(',').some(f => f !== filterValue)))
            ? `${currentFilterParam},${filterValue}`
            : `${filterValue}`

        queryStringManager.remove(groupKey, false).push(groupKey, newFilterParam)
      }

      queryStringManager.push('pagina', 1)

      selectedFiltersNew.push(filterGroup)

      return {
        ...state,
        selectedFilters: selectedFiltersNew,
        listingPage: 1,
      }
    case actionTypes.SET_HIDDEN_FILTER:
      const { key, value } = action.payload
      let hiddenSelectedFiltersNew = state.hiddenSelectedFilters

      hiddenSelectedFiltersNew.push({ key, value })

      hiddenSelectedFiltersNew = [...new Set(hiddenSelectedFiltersNew)]

      return {
        ...state,
        hiddenSelectedFilters: hiddenSelectedFiltersNew,
      }
    case actionTypes.RESET_HIDDEN_FILTER:
      return {
        ...state,
        hiddenSelectedFilters: [],
      }
    case actionTypes.SET_CONTRACTUAL_CATEGORIES:
      return {
        ...state,
        contractualCategories: action.payload,
      }
    case actionTypes.RESET_FILTERS:
      queryStringManager.reset()

      return {
        ...state,
        selectedFilters: [],
      }
    case actionTypes.TOGGLE_ORDER:
      return {
        ...state,
        selectedOrder: action.payload.order,
      }
    case actionTypes.TOGGLE_CUSTOMIZE_MODAL:
      return {
        ...state,
        customizeModalIsOpen: !state.customizeModalIsOpen,
        customizeModalStep: action.payload.step || 0,
      }
    case actionTypes.SHOW_PRICE_VAT_INCLUDED:
      return {
        ...state,
        priceVatIncluded: action.payload,
      }
    default:
      return state
  }
}
