import { createSelector } from 'reselect'

export const selectedFilters = state =>
  state.nlt.selectedFilters.filter(selectedFilterItem =>
    state.nlt.filters.some(filterGroup => filterGroup.key === selectedFilterItem.key),
  )
export const filters = state => state.nlt.filters
export const listingPage = state => state.nlt.listingPage
export const selectedOrder = state => state.nlt.selectedOrder
export const hiddenSelectedFilters = state => state.nlt.hiddenSelectedFilters
const contractualCategories = state => state.nlt.contractualCategories

export const getAllSelectedFiltersPayload = state => {
  const selectedBasicFilters = selectedFilters(state)
  let selectedHiddenFilters = hiddenSelectedFilters(state)

  selectedHiddenFilters = selectedHiddenFilters.map(({ key, value }) => ({ key, items: [{ value }] }))

  const concat = selectedBasicFilters
    .concat(selectedHiddenFilters)
    .filter(filterGroup => filterGroup.items.length > 0)

  let allSelectedFilters = concat.reduce(function (previousValue, currentValue) {
    previousValue[currentValue.key] = previousValue[currentValue.key] || []
    previousValue[currentValue.key].push(currentValue.items)
    return previousValue
  }, Object.create(null))

  const filtersPayload = {}

  for (let filterKey in allSelectedFilters) {
    filtersPayload[filterKey] = allSelectedFilters[filterKey]
      .map(item => item.map(item => item.value))
      .join(',')
  }

  return filtersPayload
}

export const filterIsMultiple = (filterKey, filters) =>
  (filters.find(filterItem => filterItem.key === filterKey) || { multiple: '0' }).multiple === '1'

export const getFiltersGroupByKey = (filters, groupKey) =>
  filters.filter(filterGroup => filterGroup.key === groupKey)

export const getFilterByGroupAndValue = (filters, groupKey, filterValue) => {
  const found = filters.find(filterGroup => filterGroup.key === groupKey)

  if (found && found.items) {
    return found.items.find(filter => filter.value === filterValue) || null
  }

  return null
}

export const getFiltersWithoutOne = (filters, groupKey) =>
  filters.filter(filterGroup => filterGroup.key !== groupKey)

export const filterIsValid = (filters, filterValue, groupKey) =>
  (groupKey === 'pagina' && /[0-9]+/.test(filterValue)) ||
  (getFiltersGroupByKey(filters, groupKey) &&
    getFiltersGroupByKey(filters, groupKey).some(filterGroup =>
      filterGroup.items.some(groupItem => groupItem.value === filterValue),
    ))

export const filterIsSelected = (filter, groupKey) =>
  createSelector(selectedFilters, filters =>
    getFiltersGroupByKey(filters, groupKey).some(filterGroup =>
      filterGroup.items.some(groupItem => groupItem.value === filter.value),
    ),
  )

export const filterIsHidden = (filter, groupKey) =>
  createSelector(hiddenSelectedFilters, hiddenFilters =>
    hiddenFilters.some(hiddenFilter => hiddenFilter.key === groupKey && hiddenFilter.value === filter.value),
  )

export const getContractualCategoryFromSlug = (slug: string) =>
  createSelector(contractualCategories, contractualCategories =>
    contractualCategories.find(
      (contractualCategory: SearchEngine.ContractualCategory) => contractualCategory.slug === slug,
    ),
  )

export const contractualCategoryIsPersonalBusiness = contractualCategory =>
  contractualCategory.type === 'BUSINESS' && contractualCategory.secondaryType === 'PRIVATE'

export const contractualCategoryIsBusiness = contractualCategory =>
  contractualCategory.type === 'BUSINESS' && contractualCategory.secondaryType === 'BUSINESS'

export const getAllOfferVariations = offerData => {
  return [
    ...offerData.variations,
    {
      id: 0,
      contractLength: offerData.contractLength,
      annualMileage: offerData.annualMileage,
      deposit: offerData.deposit,
      monthlyRate: offerData.monthlyRate,
    },
  ]
}

export const getOfferVariationsGrouped = (variations: Array<any>) => ({
  contractLengths: [
    ...new Set(
      Object.values({
        ...variations.map(variation => variation.contractLength),
      }),
    ),
  ],
  annualMileages: [
    ...new Set(
      Object.values({
        ...variations.map(variation => variation.annualMileage),
      }),
    ),
  ],
  deposits: [
    ...new Set(
      Object.values({
        ...variations.map(variation => variation.deposit),
      }),
    ),
  ],
})

export const searchVariations = (
  variations: Array<any>,
  contractLength: number | null,
  annualMileage: number | null,
  deposit: number | null,
) =>
  variations.filter(
    variation =>
      contractLength === null ||
      variation.contractLength === contractLength ||
      annualMileage === null ||
      variation.annualMileage === annualMileage ||
      deposit === null ||
      variation.deposit === deposit,
  )

export const getVariationFromCombination = (
  variations: Array<any>,
  contractLength: number,
  annualMileage: number,
  deposit: number,
) =>
  variations.filter(
    variation =>
      variation.contractLength === contractLength &&
      variation.annualMileage === annualMileage &&
      variation.deposit === deposit,
  )

export const getVariationPriceFromCombination = (
  variations: Array<any>,
  contractLength: number,
  annualMileage: number,
  deposit: number,
) =>
  (getVariationFromCombination(variations, contractLength, annualMileage, deposit).length
    ? getVariationFromCombination(variations, contractLength, annualMileage, deposit)
    : [{ monthlyRate: null }])[0].monthlyRate

export const getVariationIdFromCombination = (
  variations: Array<any>,
  contractLength: number,
  annualMileage: number,
  deposit: number,
) =>
  (getVariationFromCombination(variations, contractLength, annualMileage, deposit).length
    ? getVariationFromCombination(variations, contractLength, annualMileage, deposit)
    : [{ id: 0 }])[0].id
