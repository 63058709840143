import { AxiosResponse } from 'axios'
import request from 'src/utils/request'
import { OFFERS_EXPLORER_SERVICE_URI } from './constants'

export const getFiltersApi = (): Promise<AxiosResponse<Filter.ListData>> =>
  request.get(`${OFFERS_EXPLORER_SERVICE_URI}/search/sell/filters`)

export const getOffersApi = (postData = {}): Promise<AxiosResponse> =>
  request.post(`${OFFERS_EXPLORER_SERVICE_URI}/search/sell`, postData)

export const getHomeOffersApi = (): Promise<AxiosResponse> =>
  request.get(`${OFFERS_EXPLORER_SERVICE_URI}/search/sell/homepage`)
