import { createSelector } from 'reselect'

export const selectedFilters = state =>
  state.sell.selectedFilters.filter(selectedFilterItem =>
    state.sell.filters.some(filterGroup => filterGroup.key === selectedFilterItem.key),
  )
export const filters = state => state.sell.filters
export const listingPage = state => state.sell.listingPage
export const selectedOrder = state => state.sell.selectedOrder
export const hiddenSelectedFilters = state => state.sell.hiddenSelectedFilters

export const getAllSelectedFiltersPayload = state => {
  const selectedBasicFilters = selectedFilters(state)
  let selectedHiddenFilters = hiddenSelectedFilters(state)

  selectedHiddenFilters = selectedHiddenFilters.map(({ key, value }) => ({ key, items: [{ value }] }))

  const concat = selectedBasicFilters
    .concat(selectedHiddenFilters)
    .filter(filterGroup => filterGroup.items.length > 0)

  let allSelectedFilters = concat.reduce(function (previousValue, currentValue) {
    previousValue[currentValue.key] = previousValue[currentValue.key] || []
    previousValue[currentValue.key].push(currentValue.items)
    return previousValue
  }, Object.create(null))

  const filtersPayload = {}

  for (let filterKey in allSelectedFilters) {
    filtersPayload[filterKey] = allSelectedFilters[filterKey]
      .map(item => item.map(item => item.value))
      .join(',')
  }

  return filtersPayload
}

export const filterIsValid = (filters, filterValue, groupKey) =>
  getFiltersGroupByKey(filters, groupKey) &&
  getFiltersGroupByKey(filters, groupKey).some(filterGroup =>
    filterGroup.items.some(groupItem => groupItem.value === filterValue),
  )

export const filterIsMultiple = (filterKey, filters) => {
  return (filters.find(filterItem => filterItem.key === filterKey) || { multiple: '0' }).multiple === '1'
}

export const getFiltersGroupByKey = (filters, groupKey) =>
  filters.filter(filterGroup => filterGroup.key === groupKey)

export const getFilterByGroupAndValue = (filters, groupKey, filterValue) => {
  const found = filters.find(filterGroup => filterGroup.key === groupKey)

  if (found && found.items) {
    return found.items.find(filter => filter.value === filterValue) || null
  }

  return null
}

export const getFiltersWithoutOne = (filters, groupKey) =>
  filters.filter(filterGroup => filterGroup.key !== groupKey)

export const filterIsSelected = (filter, groupKey) =>
  createSelector(selectedFilters, filters =>
    getFiltersGroupByKey(filters, groupKey).some(filterGroup =>
      filterGroup.items.some(groupItem => groupItem.value === filter.value),
    ),
  )

export const filterIsHidden = (filter, groupKey) =>
  createSelector(hiddenSelectedFilters, hiddenFilters =>
    hiddenFilters.some(hiddenFilter => hiddenFilter.key === groupKey && hiddenFilter.value === filter.value),
  )
