import { put, select } from 'redux-saga/effects'
import { getOffersApi, getHomeOffersApi } from 'server/api/sell'

import { getOffersFail, getOffersSuccess, getFilteredOffersSuccess } from '..'
import { toggleLoader } from '../../common'
import { listingPage as getListingPage, selectedOrder, getAllSelectedFiltersPayload } from '../selectors'
import brands from 'src/containers/data/brands.json'

export function* searchOffers() {
  yield put(toggleLoader(true))

  const listingPage = yield select(getListingPage)
  const order = yield select(selectedOrder)
  const filtersPayload = yield select(getAllSelectedFiltersPayload)

  try {
    const filteredOffers = yield getOffersApi({ pagina: listingPage, ordine: order, ...filtersPayload })

    if (filteredOffers.data.items.length < 1) {
      const offers = yield getHomeOffersApi()

      yield put(
        getOffersSuccess({
          offers: offers.data,
        }),
      )
    }

    yield put(getFilteredOffersSuccess({ offers: filteredOffers.data }))
    yield put(toggleLoader(false))
  } catch (errors: any) {
    yield put(getOffersFail({ errors }))
  }
}
