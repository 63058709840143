import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'

const GlobalBaseStyle = createGlobalStyle`
  ${normalize};

  body {
    padding-right: 0 !important;
  }

  .chakra-modal__content-container {
    outline: none !important;
  }

  @keyframes circle {
    0% {
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      -o-transform: rotate(0);
      transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .spinner-circle {
    width: 155px;
    height: 155px;
    animation: circle infinite .75s linear;
    border: 2px solid #2EC58D;
    border-top-color: transparent;
    border-radius: 100%;
    display: block;
    margin: auto;
  }

  .spinner-logo {
    position: absolute;
    left: calc(50% - (100px / 2));
    top: 24%;
    width: 100px;
  }

  .offers-filters-desktop-container {
    position: relative !important;
    width: 24% !important;
    display: inline-block !important;
    float: left !important;
    /*margin-left: 8px !important;*/
    margin-right: 1.5rem;
  }

  @media (max-width: 768px) {
    .header-container {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      z-index: 99;
    }

    .offers-filters-desktop-container {
      display: none !important;
    }
  }

  @media (min-width: 1360px) {
    .hide-w-lg {
      display: none !important;
    }
  }

  @media (max-width: 600px) {
    .hide-w-lg {
      display: none !important;
    }
  }

  .rounded {
    border-radius: 10px;
  }

  /* Fonts */
  @font-face {
    font-family: Montserrat-Regular;
    src: local(Montserrat-Regular),
    url(https://static1.carplanner.com/fonts/Montserrat-Regular.woff2) format("woff2"),
    url(https://static1.carplanner.com/fonts/Montserrat-Regular.woff) format("woff"),
    url(https://static1.carplanner.com/fonts/Montserrat-Regular.ttf) format("truetype");
    font-style: normal;
    font-display: swap;
    font-stretch: normal;
  }

  @font-face {
    font-family: Montserrat-Medium;
    src: local(Montserrat-Medium),
    url(https://static1.carplanner.com/fonts/Montserrat-Medium.woff2) format("woff2"),
    url(https://static1.carplanner.com/fonts/Montserrat-Medium.woff) format("woff"),
    url(https://static1.carplanner.com/fonts/Montserrat-Medium.ttf) format("truetype");
    font-style: normal;
    font-display: swap;
    font-stretch: normal;
  }

  @font-face {
    font-family: Montserrat-SemiBold;
    src: local(Montserrat-SemiBold),
    url(https://static1.carplanner.com/fonts/Montserrat-SemiBold.woff2) format("woff2"),
    url(https://static1.carplanner.com/fonts/Montserrat-SemiBold.woff) format("woff"),
    url(https://static1.carplanner.com/fonts/Montserrat-SemiBold.ttf) format("truetype");
    font-style: normal;
    font-display: swap;
    font-stretch: normal;
  }

  @font-face {
    font-family: Montserrat;
    src: local(Montserrat-Regular),
    url(https://static1.carplanner.com/fonts/Montserrat-Regular.woff2) format("woff2"),
    url(https://static1.carplanner.com/fonts/Montserrat-Regular.woff) format("woff"),
    url(https://static1.carplanner.com/fonts/Montserrat-Regular.ttf) format("truetype");
    font-style: normal;
    font-display: swap;
    font-stretch: normal;
  }

  a, a:hover {
    color: var(--cp-colors-primaryGreen-600);
    text-decoration: none;
  }

  .menu-item-mobile {
    margin-top: 0 !important;
  }

  .menu-item-mobile + .menu-item-mobile{
    border-top: 1px solid #efeff1;
  }

  .imagePadding10{
    padding: 10px !important;
  }

  .imageBorderRadius10{
    border-radius: 10px;
  }

  .filterCheck > .chakra-checkbox__label{
    font-size: 0.875rem;
  }

  input.auto-complete-input {
    background-color: white;
  }

  .swiper {
    padding-bottom: 50px;
  }

  .has-partial-slide > .swiper {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .carousel-custom-prev,
  .carousel-custom-next {
    font-family: swiper-icons;
    font-size: 19px;
    text-transform: none !important;
    letter-spacing: 0;
    font-variant: initial;
    line-height: 16;
    position: absolute;
    background-color: transparent;
    width: 46px;
    top: 0;
    bottom: 50px;
    color: #1b4832;
    z-index: 99;
    transition: all 0.08s;
    transition-timing-function: ease-in;
  }

  .carousel-custom-prev:not(.swiper-button-disabled):hover,
  .carousel-custom-next:not(.swiper-button-disabled):hover {
    cursor: pointer;
    font-size: 24px;
    line-height: 13;
  }

  .carousel-custom-next:not(.swiper-button-disabled):hover {
    background: linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.15));
  }

  .carousel-custom-prev:not(.swiper-button-disabled):hover{
    background: linear-gradient(to left, rgba(0,0,0,0), rgba(0,0,0,0.15));
  }

  .carousel-custom-prev.swiper-button-disabled,
  .carousel-custom-next.swiper-button-disabled {
    opacity: .35;
  }

  .carousel-custom-prev {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    left: 0;
  }

  .carousel-custom-prev:after {
    content: 'prev';
  }

  .carousel-custom-next {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    right: 0;
  }

  .carousel-custom-next:after {
    content: 'next';
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 14px;
    font-weight: 900;
    color: #1b4832;
  }

  .offer-gallery-carousel .swiper-button-prev,
  .offer-gallery-carousel .swiper-button-next {
    font-size: 14px;
    font-weight: 900;
    color: #1b4832;
  }

  .offer-gallery-carousel .swiper-button-prev:after,
  .offer-gallery-carousel .swiper-button-next:after {
    font-size: 14px;
    font-weight: 900;
    color: #1b4832;
  }

  .swiper-horizontal > .swiper-scrollbar {
    margin-top: 10rem;
    width: 15.563rem;
    height: 0.5rem;
    left: calc(50% - 15.563rem / 2);
  }

  .offer-service-carousel .swiper-horizontal > .swiper-scrollbar {
    background-color: #656b72;
  }

  .offer-service-carousel .swiper-horizontal > .swiper-scrollbar .swiper-scrollbar-drag {
    background-color: #a3a9b1;
  }

  .offer-service-carousel .carousel-custom-prev,
  .offer-service-carousel .carousel-custom-next {
    width: 3rem;
    height: 3rem;
    background-color: #262626;
    padding: 10px;
    border-radius: 100%;
    font-size: 20px;
    top: 34%;
    line-height: inherit;
    font-weight: 900;
    color: white;
  }

  .offer-service-carousel .carousel-custom-prev:not(.swiper-button-disabled):hover,
  .offer-service-carousel .carousel-custom-next:not(.swiper-button-disabled):hover {
    cursor: pointer;
    font-size: 20px;
    line-height: inherit;
    background: #262626;
  }

  .offer-service-carousel .carousel-custom-prev {
    left: 1px;
    padding-left: 5px;
  }

  .offer-service-carousel .carousel-custom-next {
    right: 1px;
    padding-right: 5px;
  }

  .accordion-item-bordered + .accordion-item-bordered {
    border-top: 1px solid #dadde1;
  }

  .accordion-item-mobile-bordered + .accordion-item-mobile-bordered {
    margin-top: 0 !important;
    border-top: 1px solid #dadde1;
  }

  .faq-answer a {
    text-decoration: underline;
  }

  .faq-answer a:hover {
    text-decoration: none;
  }

  .landing-text {
    font-family: Montserrat-Medium;
    color: var(--cp-colors-baseNeutral-700);
  }

  .landing-text a {
    color: rgb(65, 164, 118) !important;
  }

  .landing-text table,
  .landing-text tr,
  .landing-text td {
    font-size: 0.9rem;
    border-collapse: collapse;
    border: 1px solid black;
  }

  .landing-text table {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .landing-text td {
    padding: 4px;
  }

  .landing-text h2 {
    font-size: 1.375rem;
    font-family: Montserrat-SemiBold;
    margin-top: 14px;
    color: var(--cp-colors-gray-800);
  }

  .landing-text h3 {
    font-size: 1.275rem;
    margin-top: 12px;
  }

  .landing-text ul,
   .landing-text ol {
    padding-left: 20px
  }

  .policies-page ul {
    padding-left: 20px
  }

  .policies-page h3 {
    font-weight: bold;
  }

  .policies-page a {
    text-decoration: underline;
  }

  .policies-page a:hover {
    text-decoration: none;
  }

  .policies-page p {
    margin: 14px 0;
  }

  #newsletter-form, #contattaci-form {
    position: relative;
    margin-bottom: 30px;
  }

  #contattaci-form fieldset {
    display: block;
    width: 100%;
    max-width: unset;
    margin-bottom: 16px;
  }

  #contattaci-form fieldset > div{
    padding-right: 12px;
  }

  @media (min-width: 768px) {
    #newsletter-form, #contattaci-form {
      margin-right: -22px;
    }

    #newsletter-form > form {
      display: flex;
      flex-wrap: wrap;
    }
  }

  #newsletter-form form > div, #contattaci-form form > div {
    margin-top: 1rem;
    flex: 0 0 50%;
  }

  #newsletter-form ul, #contattaci-form ul {
    list-style: none;
  }

  #newsletter-form a {
    color: white;
  }

  #newsletter-form label:not(.hs-form-booleancheckbox-display) .hs_error_rollup,
  #contattaci-form label:not(.hs-form-booleancheckbox-display) .hs_error_rollup
  {
    display: none;
  }

  #contattaci-form label > span {
    font-size: 0.875rem;
    color: var(--cp-colors-primaryGreen-600);
    font-weight: var(--cp-fontWeights-medium);
  }

  #contattaci-form label > span > * {
    color: var(--cp-colors-chakra-body-text);;
  }

  #newsletter-form .hs-error-msgs label,
  #contattaci-form .hs-error-msgs label
  {
    display: block;
    font-size: .8rem;
  }

  #newsletter-form .hs-error-msgs label
  {
    color: white;
  }

  #newsletter-form .legal-consent-container
  {
    position: absolute;
    bottom: -40px;
    right: 1rem;
    left: 50%;
  }

  #contattaci-form .legal-consent-container
  {
    margin: 4px 0;
  }

  #contattaci-form .legal-consent-container li
  {
    padding: 8px 0;
  }

  @media (max-width: 1090px) {
    #newsletter-form .legal-consent-container
    {
      right: 0;
      left: 0;
      bottom: -44px;
    }
  }

  #newsletter-form input[type=checkbox],
  #contattaci-form input[type=checkbox]
  {
    width: 1.3rem;
    height: 1.3rem;
    margin-right: 6px;
    background-color: transparent;
  }

  #contattaci-form input[type=checkbox]
  {
    border-color: var(--cp-colors-primaryGreen-600);
  }

  #newsletter-form .hs-form-booleancheckbox-display,
  #contattaci-form .hs-form-booleancheckbox-display
  {
    font-size: 14px;
  }

  #newsletter-form .legal-consent-container .hs-form-booleancheckbox-display>span,
  #contattaci-form .legal-consent-container .hs-form-booleancheckbox-display>span
  {
    margin-left: 27px;
  }

  #newsletter-form .legal-consent-container .hs-error-msgs,
  #contattaci-form .legal-consent-container .hs-error-msgs
  {
    margin-bottom: -19px;
  }

  #newsletter-form .hs-form-booleancheckbox-display input:checked::after,
  #contattaci-form .hs-form-booleancheckbox-display input:checked::after
  {
    content: '\\2713';
    margin-left: -14px;
    margin-top: -5px;
    font-size: 20px;
  }

  #newsletter-form .hs-form-booleancheckbox-display input:checked::after
  {
    color: white;
  }

  #newsletter-form .hs-form-required, #contattaci-form .hs-form-required {
    margin-left: 5px;
    color: #E53E3E;
  }

  #newsletter-form input, #contattaci-form input, #contattaci-form select, #contattaci-form textarea {
    background-color: white;
    color: #262626;
    outline: 2px solid transparent;
    outline-offset: 2px;
    position: relative;
    appearance: none;
    transition-property: var(--cp-transition-property-common);
    transition-duration: var(--cp-transition-duration-normal);
    padding-inline-start: var(--cp-space-4);
    font-size: var(--cp-fontSizes-lg);
    height: 3rem;
    border: 1px solid;
    border-color: inherit;
    display: block;
    width: 100%;
    border-radius: 4px;
  }

  #contattaci-form select {
    appearance: revert;
  }

  @media (min-width: 768px) {
    #newsletter-form input,
    #contattaci-form input, #contattaci-form textarea {
      display: flex;
      align-items: flex-start;
    }

    #newsletter-form input {
      width: 93%;
    }
  }

  #newsletter-form input[type=submit], #contattaci-form input[type=submit] {
    color: white;
    padding-inline-start: 0;
    padding-top: 2px;
    display: inline-block;
    border-radius: 4px;
    border-color: #1b4832;
    background-color: #1b4832;
    font-family: Montserrat;
    font-size: 1rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: center;
    text-transform: uppercase;
  }

  #newsletter-form input[type=submit] {
    width: 93%;
  }

  #contattaci-form input[type=submit] {
    padding: 0 20px;
  }

  .swiper-grid-column {
    height: 100%;
  }

  .swiper-grid-column .swiper-slide {
    height: calc((100% - 30px) / 2) !important;
  }

  .pagination a {
    line-height: inherit;
  }

  .document-upload-description a {
    color: var(--cp-colors-primaryGreen-600);
  }

  .document-upload-description a:hover {
    text-decoration: underline;
  }

  .has-popover {
    position: relative !important;

    & > .chakra-popover__popper {
      margin-left: -10px !important;
      top: 28px !important;
    }

    &:hover > .chakra-popover__popper {
      visibility: visible !important;

      & [role="tooltip"] {
        visibility: visible !important;
        opacity: 1 !important;
      }
    }
  }

  @media (min-width: 768px) {
    #newsletter-form input[type=submit] {
      width: 93% !important;
    }
    #contattaci-form input[type=submit] {
      width: unset;
    }
  }

  @media (max-width: 768px) {
    #newsletter-form .hs-form-booleancheckbox-display input:checked::after,
    #contattaci-form .hs-form-booleancheckbox-display input:checked::after
    {
      content: '\\2713';
      margin-left: -14px;
      line-height: 0;
      font-size: 20px;
    }

    #contattaci-form fieldset > div{
      padding-right: 0;
    }

    #contattaci-form fieldset .input{
      margin-right: 0 !important;
    }

    #contattaci-form form fieldset > div:nth-child(2) {
      margin-top: 16px;
    }

    #newsletter-form form .actions, #contattaci-form form .actions {
      text-align: center;
    }

    #newsletter-form input[type=submit], #contattaci-form input[type=submit] {
      width: 100%;
      padding-left: 18px;
      padding-right: 18px;
    }
  }

`

export default GlobalBaseStyle
