import { useEffect } from 'react'
import TagManager from 'react-gtm'

const Init = () => {
  useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.NEXT_PUBLIC_GTM_ID,
      defer: true
    })
  }, [])

  return null
}

export default Init
