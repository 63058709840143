import { DefaultTheme } from 'styled-components'

export const carPlannerTheme: DefaultTheme = {
  palette: {
    baseBackgroundLight: '#fcfbfe',
    baseDark: '#262626',
    baseNeutral100: '#f5f5f7',
    baseNeutral200: '#efeff1',
    baseNeutral300: '#dadde1',
    baseNeutral400: '#c1c7cf',
    baseNeutral500: '#a3a9b1',
    baseNeutral700: '#656b72',
    baseNeutral800: '#464b52',
    baseNeutral80050: 'rgba(70, 75, 82, 0.5)',
    baseWhite: '#ffffff',
    feedbackError: '#cc0b15',
    feedbackSuccess: '#148545',
    feedbackWarning: '#ffb81f',
    primaryGreen300: '#2ec58d',
    primaryGreen50: '#dff5eb',
    primaryGreen600: '#41a476',
    primaryGreen800: '#1f855f',
    primaryGreen900: '#1b4832',
    secondaryBlu700: '#42759d',
    secondaryBlu800: '#39658b',
    secondaryBlu900: '#2b4d6b',
  },

  spaces: {
    spacing_xs: '0.5rem',
    spacing_s: '0.625rem',
    spacing_m: '1rem',
    spacing_l: '1.5rem',
    spacing_xl: '2rem',
    spacing_xxl: '2.5rem',
    spacing_safe_area_mobile: '1.375rem',
    spacing_xxl_2: '3rem',
    spacing_xxl_3: '3.5rem',
    spacing_xxl_4: '4rem',
    spacing_xxl_5: '5rem',
    spacing_xxl_6: '5.5rem',
    spacing_xxs: '0.25rem',
    spacing_padding: '10rem',
  },
}
