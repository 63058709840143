import { createWrapper } from 'next-redux-wrapper'
import { applyMiddleware, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from '@redux-devtools/extension'
import rootReducer from 'src/redux/rootReducers'
import rootSaga from 'src/redux/rootSagas'

let store

const bindMiddleware = middleware => {
  if (process.env.NODE_ENV !== 'production') {
    return composeWithDevTools(applyMiddleware(...middleware))
  }
  return applyMiddleware(...middleware)
}

export const makeStore = () => {
  const sagaMiddleware = createSagaMiddleware()
  store = createStore(rootReducer, bindMiddleware([sagaMiddleware]))
  sagaMiddleware.run(rootSaga)
  return store
}

export const wrapper = createWrapper(makeStore)

export { store }
