import { AxiosResponse } from 'axios'
import request from 'src/utils/request'
import {
  USERS_SERVICE_URI,
  OFFERS_EXPLORER_SERVICE_URI,
  DEAL_SERVICE_URI,
  FILE_MANAGER_URI,
} from './constants'

export const getFiltersApi = (): Promise<AxiosResponse<Filter.ListData>> =>
  request.get(`${OFFERS_EXPLORER_SERVICE_URI}/search/rent/filters`)

export const getOffersApi = (postData = {}): Promise<AxiosResponse> =>
  request.post(`${OFFERS_EXPLORER_SERVICE_URI}/search/rent`, postData)

export const getHomeOffersApi = (): Promise<AxiosResponse> =>
  request.get(`${OFFERS_EXPLORER_SERVICE_URI}/search/rent/homepage`)

export const getSimilarOffersApi = (code): Promise<AxiosResponse> =>
  request.get(`${OFFERS_EXPLORER_SERVICE_URI}/recommendation/rent/${code}`)

export const getModelOffersApi = (brand, model): Promise<AxiosResponse<SearchEngine.BrandModel>> =>
  request.get(`${OFFERS_EXPLORER_SERVICE_URI}/search/rent/${brand}/${model}`)

export const getOfferDetailApi = (brand, code): Promise<AxiosResponse> =>
  request.get(`${OFFERS_EXPLORER_SERVICE_URI}/search/rent/${brand}/${code}`)

export const sendCustomizedStep1 = (postData): Promise<AxiosResponse<UserGlobal.User>> =>
  request.post(`${USERS_SERVICE_URI}/auth/register`, postData)

export const sendCustomizedStep2 = (userAuthId, postData): Promise<AxiosResponse> =>
  request.post(`${DEAL_SERVICE_URI}`, postData, {
    headers: {
      Authorization: userAuthId,
    },
  })

export const getDealAttachments = (
  userAuthId,
  broker,
  contractualCategory = 'tempo-indeterminato',
): Promise<AxiosResponse> =>
  request.get(`${FILE_MANAGER_URI}/files/uploaded/${broker}/${contractualCategory}`, {
    headers: {
      Authorization: userAuthId,
    },
  })

export const sendDealAttachments = (userAuthId, fileId, postData): Promise<AxiosResponse> =>
  request.post(`${FILE_MANAGER_URI}/upload/${fileId}`, postData, {
    headers: {
      Authorization: userAuthId,
      'Content-Type': 'multipart/form-data',
    },
  })

export const getFaqIndex = (): Promise<AxiosResponse> => request.get(`strapi/faq-page`)

export const getFaqAutoCompleteItems = (): Promise<AxiosResponse> => request.get(`strapi/faqs`)

export const getFaqItems = (slug): Promise<AxiosResponse> => request.get(`strapi/faqs/${slug}`)

export const getLandingPageBrandContent = (slug): Promise<AxiosResponse> =>
  request.get(`strapi/brands/${slug}`)

export const getLandingPageCityContent = (slug): Promise<AxiosResponse> =>
  request.get(`strapi/cities/${slug}`)

export const getComeFunzionaApi = (): Promise<AxiosResponse> => request.get('strapi/come-funziona-noleggio')

export const getVantaggiApi = (): Promise<AxiosResponse> => request.get('strapi/vantaggi-noleggio')
