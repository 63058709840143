export enum actionTypes {
  TOGGLE_FILTER_MENU = 'nlt/TOGGLE_FILTER_MENU',
  GET_FILTERS = 'nlt/GET_FILTERS',
  GET_FILTERS_SUCCESS = 'nlt/GET_FILTERS_SUCCESS',
  GET_FILTERS_FAIL = 'nlt/GET_FILTERS_FAIL',
  GET_OFFERS = 'nlt/GET_OFFERS',
  GET_OFFERS_SUCCESS = 'nlt/GET_OFFERS_SUCCESS',
  GET_FILTERED_OFFERS_SUCCESS = 'nlt/GET_FILTERED_OFFERS_SUCCESS',
  GET_OFFERS_FAIL = 'nlt/GET_OFFERS_FAIL',
  SET_CUSTOMIZED_OFFER = 'nlt/SET_CUSTOMIZED_OFFER',
  GET_OFFER_DETAIL = 'nlt/GET_OFFERS',
  GET_OFFER_DETAIL_SUCCESS = 'nlt/GET_OFFER_DETAIL_SUCCESS',
  GET_OFFER_DETAIL_FAIL = 'nlt/GET_OFFER_DETAIL_FAIL',
  TOGGLE_FILTER = 'nlt/TOGGLE_FILTER',
  SET_LISTING_PAGE = 'nlt/SET_LISTING_PAGE',
  SET_HIDDEN_FILTER = 'nlt/SET_HIDDEN_FILTER',
  RESET_HIDDEN_FILTER = 'nlt/RESET_HIDDEN_FILTER',
  SET_CONTRACTUAL_CATEGORIES = 'nlt/SET_CONTRACTUAL_CATEGORIES',
  RESET_FILTERS = 'nlt/RESET_FILTERS',
  TOGGLE_ORDER = 'nlt/TOGGLE_ORDER',
  TOGGLE_CUSTOMIZE_MODAL = 'nlt/TOGGLE_CUSTOMIZE_MODAL',
  RESET_OFFERS_DATA = 'nlt/RESET_OFFERS_DATA',
  PERFORM_SEARCH_OFFERS = 'nlt/PERFORM_SEARCH_OFFERS',
  SHOW_PRICE_VAT_INCLUDED = 'nlt/SHOW_PRICE_VAT_INCLUDED',
}

export interface toggleFilterMenuAction {
  type: typeof actionTypes.TOGGLE_FILTER_MENU
}

export interface getFiltersAction {
  type: typeof actionTypes.GET_FILTERS
}

export interface getFiltersSuccessAction {
  type: typeof actionTypes.GET_FILTERS_SUCCESS
  payload: { orders; filters: Array<Filter.ListItem>; suggested }
}

export interface getFiltersFailAction {
  type: typeof actionTypes.GET_FILTERS_FAIL
  payload: Errors
}

export interface getOffersStartAction {
  type: typeof actionTypes.GET_OFFERS
}

export interface getOffersSuccessAction {
  type: typeof actionTypes.GET_OFFERS_SUCCESS
  payload: {
    offers: Array<any>
  }
}

export interface getFilteredOffersSuccessAction {
  type: typeof actionTypes.GET_FILTERED_OFFERS_SUCCESS
  payload: {
    offers: {
      pageSize: number
      currentPage: number
      nextPage: number
      lastPage: number
      results: number
      items: Array<any>
    }
  }
}

export interface getOffersFailAction {
  type: typeof actionTypes.GET_OFFERS_FAIL
  payload: Errors
}

export interface getOfferDetailAction {
  type: typeof actionTypes.GET_OFFER_DETAIL
}

export interface setCustomizedOfferAction {
  type: typeof actionTypes.SET_CUSTOMIZED_OFFER
  payload: OfferGlobal.RentCustomizedOffer
}

export interface getOfferDetailSuccessAction {
  type: typeof actionTypes.GET_OFFER_DETAIL_SUCCESS
  payload: {}
}

export interface getOfferDetailFailAction {
  type: typeof actionTypes.GET_OFFER_DETAIL_FAIL
  payload: Errors
}

export interface toggleFilterAction {
  type: typeof actionTypes.TOGGLE_FILTER
  payload: { filterValue: string | number; groupKey: string }
}

export interface setListingPageAction {
  type: typeof actionTypes.SET_LISTING_PAGE
  payload: number
}

export interface setHiddenFilterAction {
  type: typeof actionTypes.SET_HIDDEN_FILTER
  payload: SearchEngine.FilterItem
}

export interface resetHiddenFilterAction {
  type: typeof actionTypes.RESET_HIDDEN_FILTER
}

export interface setContractualCategoriesAction {
  type: typeof actionTypes.SET_CONTRACTUAL_CATEGORIES
  payload: Array<SearchEngine.ContractualCategory>
}

export interface resetAllFiltersAction {
  type: typeof actionTypes.RESET_FILTERS
}

export interface toggleOrderAction {
  type: typeof actionTypes.TOGGLE_ORDER
  payload: { order: Filter.Order | null }
}

export interface toggleCustomizeModalAction {
  type: typeof actionTypes.TOGGLE_CUSTOMIZE_MODAL
  payload: { step: number }
}

export interface resetOffersDataAction {
  type: typeof actionTypes.RESET_OFFERS_DATA
}

export interface performSearchOffersAction {
  type: typeof actionTypes.PERFORM_SEARCH_OFFERS
}

export interface showPriceVatIncludedAction {
  type: typeof actionTypes.SHOW_PRICE_VAT_INCLUDED
  payload?: boolean
}

export type NltAction =
  | toggleFilterMenuAction
  | getFiltersAction
  | getFiltersSuccessAction
  | getFiltersFailAction
  | getOffersStartAction
  | getOffersSuccessAction
  | getFilteredOffersSuccessAction
  | getOffersFailAction
  | getOfferDetailAction
  | getOfferDetailSuccessAction
  | getOfferDetailFailAction
  | toggleFilterAction
  | setListingPageAction
  | setHiddenFilterAction
  | resetHiddenFilterAction
  | resetAllFiltersAction
  | toggleOrderAction
  | toggleCustomizeModalAction
  | resetOffersDataAction
  | performSearchOffersAction
  | setCustomizedOfferAction
  | setContractualCategoriesAction
  | showPriceVatIncludedAction

export const toggleFilterMenu = (): toggleFilterMenuAction => ({
  type: actionTypes.TOGGLE_FILTER_MENU,
})

export const getFilters = (): getFiltersAction => ({
  type: actionTypes.GET_FILTERS,
})

export const getFiltersSuccess = ({ orders, filters, suggested }): getFiltersSuccessAction => ({
  type: actionTypes.GET_FILTERS_SUCCESS,
  payload: { orders, filters, suggested },
})

export const getFiltersFail = (errors): getFiltersFailAction => ({
  type: actionTypes.GET_FILTERS_FAIL,
  payload: errors,
})

export const getOffersStart = (): getOffersStartAction => ({
  type: actionTypes.GET_OFFERS,
})

export const getOffersSuccess = (offers): getOffersSuccessAction => ({
  type: actionTypes.GET_OFFERS_SUCCESS,
  payload: offers,
})

export const getFilteredOffersSuccess = (offers): getFilteredOffersSuccessAction => ({
  type: actionTypes.GET_FILTERED_OFFERS_SUCCESS,
  payload: offers,
})

export const getOffersFail = (errors): getOffersFailAction => ({
  type: actionTypes.GET_OFFERS_FAIL,
  payload: errors,
})

export const getOfferDetail = (): getOfferDetailAction => ({
  type: actionTypes.GET_OFFER_DETAIL,
})

export const getOfferDetailSuccess = (offerDetail): getOfferDetailSuccessAction => ({
  type: actionTypes.GET_OFFER_DETAIL_SUCCESS,
  payload: offerDetail,
})

export const getOfferDetailFail = (errors): getOfferDetailFailAction => ({
  type: actionTypes.GET_OFFER_DETAIL_FAIL,
  payload: errors,
})

export const setCustomizedOffer = (parameter): setCustomizedOfferAction => ({
  type: actionTypes.SET_CUSTOMIZED_OFFER,
  payload: parameter,
})

export const toggleFilter = (filterValue, groupKey): toggleFilterAction => ({
  type: actionTypes.TOGGLE_FILTER,
  payload: { filterValue, groupKey },
})

export const setListingPage = (page): setListingPageAction => ({
  type: actionTypes.SET_LISTING_PAGE,
  payload: page,
})

export const setHiddenFilter = (key, value): setHiddenFilterAction => ({
  type: actionTypes.SET_HIDDEN_FILTER,
  payload: { key, value },
})

export const resetHiddenFilter = (): resetHiddenFilterAction => ({
  type: actionTypes.RESET_HIDDEN_FILTER,
})

export const setContractualCategories = (contractualCategories): setContractualCategoriesAction => ({
  type: actionTypes.SET_CONTRACTUAL_CATEGORIES,
  payload: contractualCategories,
})

export const resetAllFilters = (): resetAllFiltersAction => ({
  type: actionTypes.RESET_FILTERS,
})

export const toggleOrder = (order): toggleOrderAction => ({
  type: actionTypes.TOGGLE_ORDER,
  payload: { order },
})

export const toggleCustomizeModal = (step): toggleCustomizeModalAction => ({
  type: actionTypes.TOGGLE_CUSTOMIZE_MODAL,
  payload: step,
})

export const resetOffersData = (): resetOffersDataAction => ({
  type: actionTypes.RESET_OFFERS_DATA,
})

export const performSearchOffers = (): performSearchOffersAction => ({
  type: actionTypes.PERFORM_SEARCH_OFFERS,
})

export const showPriceVatIncluded = (vatIncluded): showPriceVatIncludedAction => ({
  type: actionTypes.SHOW_PRICE_VAT_INCLUDED,
  payload: vatIncluded || false,
})
