import { useEffect } from 'react'
import TagManager from 'react-gtm'
import Bugsnag from '@bugsnag/js'

export const Item = ({ data }) => {
  useEffect(() => {
    try {
      TagManager.dataLayer({
        dataLayer: {
          event: 'PageView',
          contentType: 'offer',
          contentIds: data.code,
          make: data.vehicle.brand,
          model: data.vehicle.model,
          transmission: data.vehicle.gear,
          stateOfVehicle: 'new',
          fuelType: data.vehicle.fuel,
          price: data.price,
          currency: '€',
        }
      })
    } catch (error: any) {
      Bugsnag.notify(error)
    }
  }, [])

  return null
}

export const User = ({ userId }) => {
  useEffect(() => {
    try {
      TagManager.dataLayer({
        dataLayer: { userId }
      })
    } catch (error: any) {
      Bugsnag.notify(error)
    }
  }, [])

  return null
}

export const List = ({ items }) => {
  useEffect(() => {
    try {
      TagManager.dataLayer({
        dataLayer: {
          ecommerce: {
            detail: {
              actionField: { list: 'Apparel Gallery' },
              products: items
            }
          }
        }
      })
    } catch (error: any) {
      Bugsnag.notify(error)
    }
  }, [items])

  return null
}
