import axios, { AxiosResponse } from 'axios'

const instance = axios.create({
  baseURL: 'https://static1.carplanner.com/website/json',
  timeout: 10000,
})

instance.interceptors.request.use(
  config => {
    if (config.url.charAt(0) === '/') {
      config.url = `${config.url}.json`
    } else if (config.url.includes('strapi')) {
      config.baseURL = 'https://cms-service.nlt.carplanner.com/'
      config.url = config.url.replace('strapi/', '')
    } else {
      config.baseURL = ''
    }
    return config
  },
  error => Promise.reject(error),
)

instance.interceptors.response.use(
  (response: AxiosResponse<any>) => ({
    status: response.status,
    success: response.status < 400,
    data: response.data?.data || response?.data || {},
    redirect: response.data?.redirect || undefined,
    error: response.data.message || null,
  }),
  error => {
    return {
      success: false,
      status: error?.response?.status || error?.status || 500,
      errors:
        error.toJSON()?.data?.errors || error.toJSON()?.data?.message || error?.message || 'Unknown error',
    }
  },
)

export default instance
